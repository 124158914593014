import React from 'react';
import TranslatorApp from './TranslatorApp';
import './firebase';

function App() {
  return (
    <div className="App">
      <TranslatorApp />
    </div>
  );
}

export default App;