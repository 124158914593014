import React, { useState, useRef, useEffect } from 'react';
import { Mic, VolumeX } from 'lucide-react';
import { transcribeAudio } from './api';  // Renaming the imported function
import { auth, db } from './firebase';

const TranslatorApp = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [englishText, setEnglishText] = useState('');
  const [spanishText, setSpanishText] = useState('');
  const [translatedEnglishText, setTranslatedEnglishText] = useState('');
  const [translatedSpanishText, setTranslatedSpanishText] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('idle'); // 'idle', 'recording', 'translating'
  const [translationTimer, setTranslationTimer] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  useEffect(() => {
    console.log('English Text:', englishText);
    console.log('Spanish Text:', spanishText);
    console.log('Translated English:', translatedEnglishText);
    console.log('Translated Spanish:', translatedSpanishText);
  }, [englishText, spanishText, translatedEnglishText, translatedSpanishText]);

  useEffect(() => {
    if (status === 'translating') {
      timerRef.current = setInterval(() => {
        setTranslationTimer(prev => prev + 1);
      }, 1000);
    } else {
      if (timerRef.current) clearInterval(timerRef.current);
      setTranslationTimer(0);
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [status]);

  useEffect(() => {
    // Cleanup function to ensure microphone is turned off when component unmounts
    return () => {
      stopMicrophone();
    };
  }, []);

  const stopMicrophone = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    mediaRecorderRef.current = null;
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 48000,
          channelCount: 1
        } 
      });
      
      const mimeTypes = [
        'audio/webm',
        'audio/webm;codecs=opus',
        'audio/ogg',
        'audio/mp4',
        'audio/wav'
      ];
  
      let detectedMimeType = mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) || '';

      if (detectedMimeType) {
        console.log(`Using mimeType: ${detectedMimeType}`);
      } else {
        console.warn('None of the preferred audio formats are supported. Using browser default.');
      }
  
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: detectedMimeType || undefined,
        audioBitsPerSecond: 128000
      });
      
      audioChunksRef.current = [];
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
  
      mediaRecorderRef.current.onstop = async () => {
        const blobMimeType = detectedMimeType || mediaRecorderRef.current.mimeType || 'audio/webm';
        const audioBlob = new Blob(audioChunksRef.current, { type: blobMimeType });
        console.log(`Creating Blob with MIME type: ${blobMimeType}`);
        await sendAudioToServer(audioBlob);
        stopMicrophone();  // Turn off microphone after sending audio
      };
  
      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
      setStatus('recording');
      setError('');
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setError('Unable to access microphone. Please ensure you have granted the necessary permissions.');
      stopMicrophone();  // Ensure microphone is off if there's an error
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setStatus('translating');
    }
  };

  const sendAudioToServer = async (audioBlob) => {
    try {
      console.log('Sending audio to server...');
      const response = await transcribeAudio(audioBlob);
      console.log('Transcription and translation response:', response);

      if (!response || (!response.isEnglish && !response.isSpanish)) {
        throw new Error('Invalid response from transcription service');
      }

      if (response.isEnglish) {
        setEnglishText(response.original_text);
        setTranslatedSpanishText(response.translated_text);
        setSpanishText('');
        setTranslatedEnglishText('');
      } else if (response.isSpanish) {
        setSpanishText(response.original_text);
        setTranslatedEnglishText(response.translated_text);
        setEnglishText('');
        setTranslatedSpanishText('');
      }
      setStatus('idle');
    } catch (error) {
      console.error('Error processing audio:', error);
      setError(`Error processing audio: ${error.message}. Please try again.`);
      setStatus('idle');
    }
  };

/*   const translateText = async (text, sourceLang, targetLang) => {
    try {
      console.log(`Attempting to translate: "${text}" from ${sourceLang} to ${targetLang}`);
      const response = await translateTextAPI(text, sourceLang, targetLang);
      console.log('Translation response:', response);
  
      if (!response || !response.translated_text) {
        throw new Error('Invalid response from translation service');
      }
  
      if (sourceLang === 'en') {
        setTranslatedSpanishText(response.translated_text);
        setTranslatedEnglishText('');
      } else {
        setTranslatedEnglishText(response.translated_text);
        setTranslatedSpanishText('');
      }
    } catch (error) {
      console.error('Error translating text:', error);
      if (error.response) {
        console.error('Error details:', error.response.data);
        setError(`Error translating text: ${error.response.data.detail || error.message}. Please try again.`);
      } else {
        console.error('Error details:', error.message);
        setError(`Error translating text: ${error.message}. Please try again.`);
      }
    }
  }; */

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const getButtonColor = () => {
    if (status === 'recording') return 'bg-red-600';
    if (status === 'translating') return 'bg-gray-400';
    return 'bg-green-600';
  };

  const getStatusText = () => {
    if (status === 'recording') return 'Recording';
    if (status === 'translating') return `Translating... ${translationTimer}s`;
    return 'Tap to speak';
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Keep existing layout for English and Spanish text display */}
      <div className="flex-1 p-4">
        <div className="bg-white rounded-lg shadow-md p-4 h-full">
          <h2 className="text-xl font-bold mb-4">English</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Original:</h3>
            <p>{englishText || "Waiting for speech..."}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">Translated to Spanish:</h3>
            <p>{translatedSpanishText || "Translation will appear here..."}</p>
          </div>
        </div>
      </div>
      <div className="flex-1 p-4">
        <div className="bg-white rounded-lg shadow-md p-4 h-full">
          <h2 className="text-xl font-bold mb-4">Spanish</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Original:</h3>
            <p>{spanishText || "Esperando el habla..."}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">Translated to English:</h3>
            <p>{translatedEnglishText || "La traduccion aparecera aqui..."}</p>
          </div>
        </div>
      </div>
      {error && (
        <div className="fixed top-4 left-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}
      <div className="fixed bottom-4 right-4 flex items-center">
        <span className="mr-4 text-lg font-semibold">
          {getStatusText()}
          {status === 'recording' && (
            <span className="ml-2 inline-block w-3 h-3 bg-red-600 rounded-full animate-pulse"></span>
          )}
        </span>
        <button 
          onClick={toggleRecording}
          className={`p-4 ${getButtonColor()} text-white rounded-full`}
          disabled={status === 'translating'}
        >
          {isRecording ? <VolumeX size={32} /> : <Mic size={32} />}
        </button>
      </div>
    </div>
  );
};

export default TranslatorApp;