import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let api;

if (IS_PRODUCTION) {
  const API_KEY = process.env.REACT_APP_API_GATEWAY_KEY;
  if (!API_KEY) {
    console.error('API Gateway key is not set in the production environment');
  }
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'x-api-key': API_KEY
    }
  });
} else {
  api = axios.create({
    baseURL: API_URL
  });
}

export const transcribeAudio = async (audioBlob) => {
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.webm');
  
  try {
    const response = await api.post('/api/transcribe', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in transcription:', error);
    throw error;
  }
};

export const translateText = async (text, sourceLang, targetLang) => {
  try {
    const response = await api.post('/api/translate', {
      text,
      source_language: sourceLang,
      target_language: targetLang
    });
    return response.data;
  } catch (error) {
    console.error('Error in translation:', error);
    throw error;
  }
};

// Comment TTS related API calls - keep it till get back to TTS functionality
// export const textToSpeech = async (text) => {
//   try {
//     const response = await axios.post(`${API_URL}/api/tts`, { text });
//     return response.data;
//   } catch (error) {
//     console.error('Error in TTS:', error);
//     throw error;
//   }
// };